<template>
  <div id="assignedItem">
    <div class="page-container mb-2">
      <span class="text-filter">Bộ Lọc</span>
      <b-row class="mt-1">
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Năm</label>
            <v-select
              :reduce="title => title"
              :options="dataYear || []"
              :placeholder="'Năm'"
              @input="filterData('year', $event)"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Doanh nghiệp</label>
            <v-select
              :reduce="title => title.id"
              label="name"
              :options="dataAllBusiness || []"
              :placeholder="'Doanh nghiệp'"
              @input="filterData('bussinessId', $event)"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Tình trạng</label>
            <v-select
              :reduce="title => title.en"
              label="vi"
              :options="expireReport || []"
              :placeholder="'Tình trạng'"
              @input="filterData('expireReport', $event)"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Trạng thái</label>
            <v-select
              :reduce="title => title.en"
              label="vi"
              :options="statusReport || []"
              :placeholder="'Trạng thái'"
              @input="filterData('statusReport', $event)"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Hình thức</label>
            <v-select
              :reduce="title => title.en"
              label="vi"
              :options="submitReportType || []"
              :placeholder="'Hình thức'"
              @input="filterData('submitReportType', $event)"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Loại hình doanh nghiệp</label>
            <v-select
              :reduce="title => title.id"
              label="name"
              :options="dataTypeBussiness || []"
              :placeholder="'Loại hình doanh nghiệp'"
              @input="filterData('bussinessTypeId', $event)"
            >
              <template #option="{name, code}">
                <span
                  class="ml-50"
                >{{ code }} - {{ name }}</span>
              </template>
              <template
                #selected-option="{name, code}"
                class="scroll-select-option"
              >
                <span
                  class="ml-50"
                >{{ code }} - {{ name }}</span>
              </template>
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Lọc theo đơn vị xảy ra tai nạn</label>
            <v-select
              :reduce="title => title.status"
              label="name"
              :options="accidentCase || []"
              :placeholder="'Lọc theo đơn vị xảy ra tai nạn'"
              @input="filterData('isAccidentCase', $event)"
            >

              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
        >
          <b-form-group>
            <label for="InputHelp">Ngành nghề</label>
            <business-sector-select
              @input="filterData('bussinessSectorId', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div
      class="page-container-table"
    >
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm người dùng'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideDelete="false"
        :hideImportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        class="mr-1"
        @clickExportExcel="downloadExportFile"
        @search="search($event)"
      >

        <template #action-left>
          <b-dropdown
            v-b-tooltip.hover.top="'Xuất báo cáo'"
            variant="outline-primary"
            :disabled="disabled"
            no-caret
            style="width: 38px; height: 38px;"
            class="dropdown-export-excel"
          >
            <template #button-content>
              <feather-icon icon="BookIcon" />
            </template>
            <div>
              <b-dropdown-item
                @click="downloadExportAll(true)"
              >
                <span
                  class="dropdown-text"
                >Xuất tất cả</span>
              </b-dropdown-item>
              <b-dropdown-item

                @click="downloadExportAll(false)"
              >
                <span
                  class="dropdown-text"
                >Xuất theo trang</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>
      </button-all-header>

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :select-options="{
          enabled: false,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã loại báo cáo -->
          <span
            v-if="props.column.field === 'bussinessCode'"
            class="text-nowrap edit-report"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.bussinessCode) }}</span>
            </b-badge>
          </span>

          <!-- Column:Hạn nộp báo cáo -->
          <span v-else-if="props.column.field === 'dateSend'">
            <span class="text-nowrap">{{ props.row.dateSend | formatDateToDDMM }}</span>
          </span>

          <!-- Column:Trạng thái -->
          <span v-else-if="props.column.field === 'submitReportStatusString'">
            <b-badge
              pill
              :variant="statusVariant(props.row.submitReportStatus)"
              class="border-status"
            >
              <span>{{ props.row.submitReportStatus === 'Send' ? 'Chờ duyệt' : 'Đã duyệt' }}</span>

            </b-badge>
          </span>
          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'actionFunction'">
            <feather-icon
              v-b-tooltip.hover.top="'Xem tài liệu'"
              icon="EyeIcon"
              size="18"
              @click="openSeePdf(props.row.id, 'PDF', props.row.isQrCode, props.row.name, props.row.filePath)"
            />

            <feather-icon
              v-if="props.row.submitReportStatus === 'Send' && roleUser!== 'EducationDepartment'"
              v-b-tooltip.hover.top="'Duyệt'"
              icon="CheckCircleIcon"
              size="18"
              class="ml-2"
              @click="showModalAccept(props.row.id)"
            />
            <feather-icon
              v-if="roleUser!== 'EducationDepartment'"
              v-b-tooltip.hover.top="'Trả lại'"
              icon="RotateCcwIcon"
              size="18"
              class="ml-2"
              @click="showModalReject(props.row.id)"
            />
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- Thu hồi -->
      <modal-reject
        :id="modalReject"
        @handleReject="handleReject"
      />

      <!-- modal duyệt-->
      <confirm-modal
        :id="confirmModalId"
        :title="title"
        :content="modalContent"
        @accept="approveReport"
      />

      <!-- Xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />

      <!-- Xem file pdf -->
      <modal-see-pdf-embed
        :id="modalSeePdfEmbed"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />
    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BBadge, BRow, BCol, BFormGroup, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalReject from '../components/ModalReject.vue'
import ModalSeePdf from '../components/ModalSeePdf.vue'
import ModalSeePdfEmbed from '../components/ModalSeePdfEmbed.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import DataFilter from '../../constants/DataFilter'
import BusinessSectorSelect from '@/views/management-business/business-sector/pages/components/BusinessSectorSelect.vue'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    BBadge,
    ModalReject,
    ConfirmModal,
    BRow,
    BCol,
    BFormGroup,
    VSelect,
    ModalSeePdf,
    ModalSeePdfEmbed,
    VBTooltip,
    BusinessSectorSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      accidentCase: [
        {
          name: 'Tất cả',
          status: false,
        },
        {
          name: 'Có xảy ra tai nạn lao động',
          status: true,
        },
      ],
      roleUser: JSON.parse(localStorage.getItem('userData')).roleIdentity,
      dataYear: [],
      dataTable: [],
      columns: [
        {
          label: 'MÃ DOANH NGHIỆP',
          field: 'bussinessCode',
          sortable: false,
        },
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'LOẠI BÁO CÁO',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY NỘP',
          field: 'dateSend',
          sortable: false,
        },
        {
          label: 'NĂM',
          field: 'year',
          sortable: false,
        },
        {
          label: 'Tình Trạng',
          field: 'status',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'submitReportStatusString',
          sortable: false,
        },
        {
          label: 'HÌNH THỨC',
          field: 'submitReportTypeString',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      modalContent: 'Bạn có muốn duyệt báo cáo ',
      showBtnMultiDelete: false,
      userId: '',
      arrayExcel: null,
      totalRecord: 10,
      modalReject: 'comfirm-reject',
      modalSeePdf: 'confirm-see-PDF',
      modalSeePdfEmbed: 'confirm-see-PDF-embed',
      confirmModalId: 'comfirm-modal',
      title: 'Bạn có chắc chắn duyệt?',
      urlQuery: {
        expireReport: '',
        submitReportType: '',
        bussinessId: '',
        statusReport: '',
        bussinessTypeId: '',
        isAccidentCase: false,
        year: null,
        bussinessSectorId: null,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      urlQueryExcel: {
        isAccidentCase: false,
        bussinessTypeId: '',
        submitReportType: '',
        statusReport: '',
        expireReport: '',
        bussinessId: '',
        year: null,
        bussinessSectorId: null,
      },
      reportId: null,
      fileType: null,
      qrCode: null,
      nameFile: null,
      pdfsrc: null,
      filePath: null,

      submitReportType: [
        {
          vi: 'Nhập tự động',
          en: 'Auto',
        },
        {
          vi: 'Nhập từ file',
          en: 'File',
        },
        {
          vi: 'Nhập từ Form',
          en: 'Auto',
        },
      ],

      expireReport: [
        {
          vi: 'Đúng hạn',
          en: 'OnTime',
        },
        {
          vi: 'Quá hạn',
          en: 'OutOfDate',
        },
      ],
      statusReport: [
        {
          vi: 'Đã duyệt',
          en: 'Approve',
        },
        {
          vi: 'Chờ duyệt',
          en: 'Send',
        },
      ],
      dataTypeBussiness: [],
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
    ...mapGetters('performTraining', ['dataAllBusiness']),
    statusVariant() {
      const statusColor = {
        // Chưa kích hoạt
        Send: 'warning',
        // Kích hoạt
        Approve: 'primary',
      }
      return status => statusColor[status]
    },
  },
  mounted() {
    this.fetchDataComboboxAllBusiness()
  },
  created() {
    this.fetchDataSubmitReport(this.urlQuery)
    this.getYear()
    this.dataYear = this.yearList
    this.getDataTypeOfBussiness()
  },
  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    ...mapActions('fiterYear', ['getYear']),

    async getDataTypeOfBussiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.BUSSINESS_TYPE)
      this.dataTypeBussiness = data?.data?.pageLists
    },

    // Show modal thu hồi
    showModalReject(id) {
      this.reportId = id
      this.$bvModal.show(this.modalReject)
    },

    // Show modal duyệt
    showModalAccept(id) {
      this.reportId = id
      this.$bvModal.show(this.confirmModalId)
    },

    async openSeePdf(id, file, qrCode, name, filePath) {
      this.reportId = id
      this.fileType = file
      // this.qrCode = qrCode
      this.nameFile = name
      // this.filePath = filePath
      const payload = {
        submitReportId: this.reportId,
        reportFormat: this.fileType,
      }
      await axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: payload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        this.pdfsrc = url
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file excel
    async downloadExportAll(downloadAll) {
      this.$showAllPageLoading()
      const payload = {
        downloadAll,
        ...this.urlQuery,
      }
      await axiosApiInstance({
        url: 'SituationReport/download-all-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: payload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DoanhNghiepDaNopBaoCao.zip') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
      this.$hideAllPageLoading()
    },
    // Tải file excel
    downloadExportFile() {
      axiosApiInstance({
        url: 'SituationReport/export-excel-report-send', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: this.urlQueryExcel,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DoanhNghiepDaNopBaoCao.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Down file pdf
    downloadFilePdf() {
      const payload = {
        submitReportId: this.reportId,
        reportFormat: this.fileType,
      }
      axiosApiInstance({
        url: '/SubmitReport/export-report', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: payload,
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.nameFile}.pdf`) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Duyệt báo cáo
    async approveReport() {
      const model = {
        id: this.reportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.APPROVE_SUBMITED_REPORT, model).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Duyệt thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    async handleReject(val) {
      const payload = {
        ...val,
        id: this.reportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REJECT_SUBMITED_REPORT, payload).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Gửi trả thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalReject)
        this.fetchDataSubmitReport(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchDataSubmitReport(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMITED_REPORT, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    filterData(key, value) {
      this.urlQueryExcel[key] = value
      this.urlQuery[key] = value
      this.urlQuery.pageNumber = 1
      this.fetchDataSubmitReport(this.urlQuery)
    },

    // Tìm kiếm theo keyword
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataSubmitReport(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataSubmitReport(this.urlQuery)
      }
    },

    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataSubmitReport(this.urlQuery)
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.dropdown-export-excel {
    button {
      padding: 9.5px 9px 7px;
    }
  }
#assignedItem {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }

  .border-status {
    border-radius: 4px;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
